import { experiences } from "../../portfolio";
import ExperienceItem from "./Experiences";

const Experiences = () =>
  experiences.length > 0 && (
    <section id="experiences" className="section projects">
      <h2 className="section__title">Experiences</h2>

      <div className="timeline-container">
        {experiences.map((data, idx) => (
          <ExperienceItem key={idx} id={`experience_${idx}`} data={data} />
        ))}
      </div>
    </section>
  );

export default Experiences;
