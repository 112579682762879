const header = {
  // all the properties are optional - can be left empty or deleted
  homepage: "https://vraj-patel.com/",
  title: "VP.",
};

const about = {
  // all the properties are optional - can be left empty or deleted
  name: "Vraj Patel",
  role: "Software Engineer",
  description:
    "As a software engineer with a diverse skill set, I have gained extensive experience in front-end 🎨, back-end 🏗️, and firmware development 💾. Apart from my technical skills, I am a car enthusiast who is fascinated by the intersection of technology and automobiles 🏎️. In my free time, I enjoy playing different sports 🏀, hiking 🥾, and skiing 🎿. Overall, my passion for technology, and my love for cars and the outdoors make me a well-rounded software engineer who is always eager to take on new challenges and create innovative solutions 💻.",
  social: {
    linkedin: "https://www.linkedin.com/in/vraj-pat/",
    github: "https://github.com/Vraj-P",
  },
};

const experiences = [
  {
    title: "MappedIn | Software Engineering Intern",
    date: "Sep 2023 - Dec 2023",
    description:
      "Worked on the SDK team to develop an efficient and scalable indoor mapping solution using TypeScript, ThreeJS, and WebGL. 🗺️",
  },
  {
    title: "TheScore | Software Engineering Intern",
    date: "Jan 2023 - Apr 2023",
    description:
      "Worked on the back-end team utilizing technologies including Elixir, Phoenix, Phoenix LiveView, gRPC, Postgres SQL. 🏀",
  },
  {
    title: "PointClickCare | Software Engineering Intern",
    date: "Jan 2022 - May 2022",
    description:
      "Delivered high-priority tasks to help in the re-design of PointClickCare’s application using React, JavaScript, and Java. 🏥",
  },
  {
    title: "SiteScope | CTO/Co-Founder",
    date: "Feb 2021 - Jan 2022",
    description:
      "Developed a hardhat detection software with Python, TensorFlow, and OpenCV to improve safety and reduce potential head injuries on construction sites. 👷‍♂️",
  },
  {
    title: "Seismic | Software Developer Intern",
    date: "May 2021 - Aug 2021",
    description:
      "Developed time-sensitive client-requested features utilizing React, Angular, Node, Redux, and MongoDB. 📈",
  },
  {
    title: "SuperWorld | Software Developer Intern",
    date: "Sep 2020 - Dec 2020",
    description:
      "Developed the company’s web application using GatsbyJS, JavaScript, Sass, and Material UI. 🌎",
  },
];

const projects = [
  // projects can be added an removed
  // if there are no projects, Projects section won't show up
  {
    name: "OddJobsCo 🛠️",
    description:
      "An application where individuals can posts jobs that they need done by others. The jobs can be anything from moving furniture to fixing a leaky faucet.",
    stack: ["React", "Django", "Redux", "GraphQL", "MySQL"],
    sourceCode: "https://github.com/Vraj-P/OddJobsCO",
  },
  {
    name: "RTOS 🕐",
    description:
      "A real-time operating system that can perform memory management, process scheduling, and inter-process communication.",
    stack: ["C"],
    sourceCode: "https://github.com/Vraj-P/RTOS",
  },
  {
    name: "MLB Hall of Fame Predictor ⚾️",
    description:
      "Achieved 96% accuracy when predicting if a player will be inducted in the MLB Hall of Fame using SK-Learn.",
    stack: ["Python", "SK-Learn", "MySQL"],
    sourceCode: "https://github.com/Vraj-P/MLBPredictor",
  },
  {
    name: "Celavent 🎉",
    description:
      "Created an application that allows vendors to bid for any events that you are planning.",
    stack: ["HTML", "CSS", "JavaScript", "Node", "Express", "MongoDB"],
    sourceCode: "https://github.com/Vraj-P/celavent",
  },
  {
    name: "Stock Tracker 📈",
    description:
      "An application that allows users to track any stocks they purchase. This application allows used to keep track of their portfolio.",
    stack: ["React", "Node", "Express", "PostgreSQL"],
    sourceCode: "https://github.com/Vraj-P/StockTracker",
  },
  {
    name: "Vegetarian Element 🧑‍🍳",
    description:
      "A web application that allows users to search for vegetarian recipies using the Edamam search API.",
    stack: ["React"],
    sourceCode: "https://github.com/Vraj-P/Vegetarian-Element",
  },
];

const skills = [
  // skills can be added or removed
  // if there are no skills, Skills section won't show up
  "AWS",
  "React",
  "JavaScript",
  "TypeScript",
  "Python",
  "Redux",
  "C",
  "Material UI",
  "Git",
  "CI/CD",
  "C++",
  "Java",
  "Node",
  "Express",
  "MongoDB",
  "MySQL",
  "PostgreSQL",
  "GraphQL",
  "Django",
  "HTML",
  "CSS",
  "Elixir",
  "Phoenix",
  "gRPC",
  "Kafka",
  "Cron Jobs",
  "Docker",
  "Oban Workers",
  "Matlab",
];

const contact = {
  email: "vrajpat.01@gmail.com",
};

export { header, about, experiences, projects, skills, contact };
