import { useState } from "react";
import Header from "./components/Header/Header";
import About from "./components/About/About";
import Experiences from "./components/Experiences/ExperienceContainer";
import Projects from "./components/Projects/Projects";
import Skills from "./components/Skills/Skills";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import Contact from "./components/Contact/Contact";
import Footer from "./components/Footer/Footer";
import "./App.css";

const App = () => {
  const [theme, setTheme] = useState("dark");

  const updateTheme = (newTheme) => {
    setTheme(newTheme);
  };

  return (
    <div id="top" className={`${theme} app`}>
      <Header value={theme} updateValue={updateTheme} />

      <main>
        <About />
        <Experiences />
        <Projects />
        <Skills />
        <Contact />
      </main>

      <ScrollToTop />
      <Footer />
    </div>
  );
};

export default App;
